import React from 'react';
import '../style.scss';
import StakedNormalFarm from './StakedNormalFarm';
import StakedSuperFarm from './StakedSuperFarm';

const StakedNfts = () => {
  return (
    <>
      <StakedNormalFarm />
      <StakedSuperFarm />
    </>
  );
};

export default StakedNfts;
