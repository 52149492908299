import React from 'react';
import { denominate } from '@multiversx/sdk-dapp/utils';
import { Accordion } from 'react-bootstrap';
import { AccountDetailsContext } from 'components/AccountDetails';
import '../style.scss';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

const Details = () => {
  const accountDetails = React.useContext(AccountDetailsContext);
  const stakedNftsSum = () => {
    return (
      (accountDetails?.normalFarmStakedNfts?.length || 0) +
      (accountDetails?.superFarmStakedNfts?.length || 0)
      // (accountDetails?.stakedBoosters?.length || 0)
    );
  };

  const claimableRewardsSum = () => {
    return accountDetails?.normalFarmReward
      ?.plus(accountDetails?.superFarmReward || 0)
      .toFixed(2);
  };

  const multiplerSum = (stakedNfts: any[]): number => {
    return stakedNfts.reduce((acc, nft) => {
      const nonce = Number(nft.nonce).toString(16);

      return (
        acc +
        (accountDetails?.multipliers?.[
          nonce.padStart(nonce.length + (nonce.length % 2), '0')
        ] || 0) /
          10000
      );
    }, 0);
  };

  return (
    <div className='card-wrapper'>
      <h4>Details</h4>

      <div className='mb-3' style={{ fontSize: '1.2em' }}>
        $TOLKEN amount:
        <span className='text-warning pl-2' style={{ fontWeight: 'bold' }}>
          {denominate({
            input: accountDetails?.tokenAmmount || '0',
            decimals: 2,
            addCommas: true,
            showLastNonZeroDecimal: false
          }).replaceAll(',', '')}{' '}
          $TOLKEN
        </span>
      </div>
      <div className='mb-3' style={{ fontSize: '1.2em' }}>
        Claimable Rewards:
        <span className='text-warning pl-2' style={{ fontWeight: 'bold' }}>
          {claimableRewardsSum()?.toString() || 0} $TOLKEN
        </span>
      </div>
      <div className='mb-3' style={{ fontSize: '1.2em' }}>
        Normal Farm Multiplier:
        <span className='text-warning pl-2' style={{ fontWeight: 'bold' }}>
          {multiplerSum(accountDetails?.normalFarmStakedNfts || []) || 0}
        </span>
      </div>
      <div className='mb-3' style={{ fontSize: '1.2em' }}>
        Super Farm Multiplier:
        <span className='text-warning pl-2' style={{ fontWeight: 'bold' }}>
          {multiplerSum(accountDetails?.superFarmStakedNfts || []) *
            (accountDetails?.stakedBoosters?.length ? 2 : 1)}
        </span>
      </div>
      <div className='mb-3' style={{ fontSize: '1.2em' }}>
        Staked NFTs:
        <span className='text-warning pl-2' style={{ fontWeight: 'bold' }}>
          {stakedNftsSum()}
        </span>
      </div>
      {/* <div className='mb-3' style={{ fontSize: '1.2em' }}>
        Normal Farm total staked:
        <span className='text-warning pl-2' style={{ fontWeight: 'bold' }}>
          {accountDetails?.normalFarmTotalNfts?.toFixed(0) || 0}
        </span>
      </div>
      <div className='mb-3' style={{ fontSize: '1.2em' }}>
        Super Farm total staked:
        <span className='text-warning pl-2' style={{ fontWeight: 'bold' }}>
          {accountDetails?.superFarmTotalNfts?.toFixed(0) || 0}
        </span>
      </div> */}
    </div>
  );
};

export default Details;
