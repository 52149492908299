import getNftAccounts from 'apiRequests/getNftAccounts';
import { AccountDetailsContext } from 'components/AccountDetails';
import React from 'react';

const Holders = () => {
  const accountDetails = React.useContext(AccountDetailsContext);
  const [circulatingSupplySnapshot, setCirculatingSupplySnapshot] =
    React.useState<any[]>([]);
  const [circulatingSupplyUniqueHolders, setCirculatingSupplyUniqueHolders] =
    React.useState<string[]>([]);
  const [stakingSupplyUniqueHolders, setStakingSupplyUniqueHolders] =
    React.useState<string[]>([]);
  const [stakingPool1Holders, setStakingPool1Holders] = React.useState<
    string[]
  >([]);
  const [stakingPool2Holders, setStakingPool2sHolders] = React.useState<
    string[]
  >([]);
  const [uniqueHolders, setUniqueHolders] = React.useState<string[]>([]);
  const [snapshotStep, setSnapshotStep] = React.useState<SNAPSHOT_STEP>(
    SNAPSHOT_STEP.NOT_STARTED
  );

  const performRegularSnapshot = async () => {
    const result = await getNftAccounts('https://api.multiversx.com');
    if (!result.success) {
      window.alert('something went wrong');
      return;
    }
    setCirculatingSupplySnapshot(result.data);
    const res = result.data.map((d: any) => d.address).filter(onlyUnique);
    setCirculatingSupplyUniqueHolders(res);
    return res;
  };

  const performStakingSnapshot = async () => {
    if (accountDetails?.loadAllStakingAddresses === undefined) {
      window.alert('something went wrong on step 2');
      return;
    }
    const dataPool1 = await accountDetails?.loadAllStakingAddresses(1);
    const dataPool2 = await accountDetails?.loadAllStakingAddresses(2);
    setStakingPool1Holders(dataPool1);
    setStakingPool2sHolders(dataPool2);
    const concatenatedData = dataPool1.concat(dataPool2);
    const result = concatenatedData.filter(onlyUnique);
    setStakingSupplyUniqueHolders(result);
    return result;
  };

  const startSnapshot = async () => {
    setSnapshotStep(SNAPSHOT_STEP.STARTED_CIRCULATING_SUPPLY_SNAPSHOT);
    const snapshot1 = await performRegularSnapshot();
    setSnapshotStep(SNAPSHOT_STEP.STARTED_STAKED_SUPPLY_SNAPSHOT);
    const snapshot2 = await performStakingSnapshot();

    const fullSnapshot = snapshot1.concat(snapshot2);
    setUniqueHolders(fullSnapshot.filter(onlyUnique));
    setSnapshotStep(SNAPSHOT_STEP.DONE);
  };

  const onlyUnique = (value: any, index: number, self: any[]) =>
    self.indexOf(value) === index;

  return (
    <>
      {snapshotStep === SNAPSHOT_STEP.NOT_STARTED && (
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center mt-5'>
              <button className='btn btn-primary' onClick={startSnapshot}>
                Load holders info
              </button>
            </div>
          </div>
        </div>
      )}
      {snapshotStep === SNAPSHOT_STEP.STARTED_CIRCULATING_SUPPLY_SNAPSHOT && (
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center mt-5'>
              <h3>Fetching circulating NFTs holders</h3>
            </div>
          </div>
        </div>
      )}
      {snapshotStep === SNAPSHOT_STEP.STARTED_STAKED_SUPPLY_SNAPSHOT && (
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center mt-5'>
              <h3>Fetching staked NFTs holders</h3>
            </div>
          </div>
        </div>
      )}
      {snapshotStep === SNAPSHOT_STEP.DONE && (
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center mt-5'>
              <h3>Holders snapshot done</h3>
            </div>
          </div>
        </div>
      )}
      {snapshotStep !== SNAPSHOT_STEP.NOT_STARTED && (
        <>
          <div className='container mt-5 mb-5'>
            {circulatingSupplySnapshot.length > 0 &&
              circulatingSupplyUniqueHolders.length > 0 && (
                <div className='row'>
                  <div className='col-12 text-center'>
                    <h4>
                      <strong>
                        Circulating supply snapshot info (circulating = not
                        staked)
                      </strong>
                    </h4>
                    <p>Fetched {circulatingSupplySnapshot.length} NFTs</p>
                  </div>
                  <div className='col-12 text-center mb-5'>
                    {circulatingSupplyUniqueHolders.length} unique holders, out
                    of which{' '}
                    {
                      circulatingSupplyUniqueHolders.filter((h) =>
                        h.includes('qqqqqqqqqq')
                      ).length
                    }{' '}
                    are smart contracts
                  </div>
                </div>
              )}
            {stakingSupplyUniqueHolders.length > 0 &&
              stakingPool1Holders.length > 0 &&
              stakingPool2Holders.length > 0 && (
                <div className='row mt-5'>
                  <div className='col-12 text-center'>
                    <h4>
                      <strong>Staked supply snapshot info</strong>
                    </h4>
                  </div>
                  <div className='col-12 text-center'>
                    {stakingSupplyUniqueHolders.length} unique holders in total
                  </div>
                  <div className='col-6 text-center'>
                    {stakingPool1Holders.length} unique holders in pool 1 (Super
                    Farm)
                  </div>
                  <div className='col-6 text-center mb-5'>
                    {stakingPool2Holders.length} unique holders in pool 2
                    (Normal Farm)
                  </div>
                </div>
              )}
            {uniqueHolders.length > 0 && (
              <div className='row mt-5'>
                <div className='col-12 text-center'>
                  <h2>
                    There is a total of {uniqueHolders.length} unique holders of
                    MaiarPunks NFTS
                  </h2>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Holders;
export enum SNAPSHOT_STEP {
  NOT_STARTED = 0,
  STARTED_CIRCULATING_SUPPLY_SNAPSHOT = 1,
  STARTED_STAKED_SUPPLY_SNAPSHOT = 2,
  DONE = 3
}
