import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
import React from 'react';
import { Navbar as BsNavbar, NavItem, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { routeNames } from 'routes';

const Navbar = () => {
  const { address } = useGetAccountInfo();

  const handleLogout = () => {
    logout(`${window.location.origin}/`);
  };

  const isLoggedIn = Boolean(address);

  return (
    <BsNavbar
      className='px-4 py-3'
      style={{ backgroundColor: 'rgba(27,0,52,0.74)' }}
    >
      <div className='container-fluid'>
        <Link
          className='d-flex align-items-center navbar-brand mr-0'
          to={isLoggedIn ? routeNames.dashboard : routeNames.home}
        >
          <img
            src='https://maiarpunks.store/wp-content/uploads/cropped-logobanner.png'
            style={{ display: 'block', height: '4em', objectFit: 'contain' }}
          />
          {/* <span className='dapp-name text-muted'>{dAppName}</span> */}
        </Link>

        <Nav className='ml-auto '>
          {isLoggedIn && (
            <NavItem>
              <Button variant='outline-light' onClick={handleLogout}>
                Logout
              </Button>
            </NavItem>
          )}
        </Nav>
      </div>
    </BsNavbar>
  );
};

export default Navbar;
