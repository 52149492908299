import React, { useEffect, useState } from 'react';
import { AbiRegistry, Address, SmartContract } from '@multiversx/sdk-core';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { contractAddress } from 'config';
import stakingAbiJson from './staking.abi.json';

export interface ScDetails {
  abiRegistry: AbiRegistry;
  contract: SmartContract;
}

export const ScContext = React.createContext<ScDetails | null>(null);

const ScProvider = ({ children }: { children: React.ReactNode }) => {
  const [details, setDetails] = useState<ScDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const abiRegistry = AbiRegistry.create(stakingAbiJson);
    const contract = new SmartContract({
      address: new Address(contractAddress),
      abi: abiRegistry
    });
    setDetails({
      abiRegistry,
      contract
    });
    setIsLoading(false);
  }, []);

  return (
    <ScContext.Provider value={details}>
      {!isLoading && children}
    </ScContext.Provider>
  );
};
export default ScProvider;
