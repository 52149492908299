import {
  BigUIntType,
  BytesType,
  FieldDefinition,
  StructType,
  U64Type
} from '@multiversx/sdk-core';

export type StakeType = 'normal' | 'super' | '';

export const TokenInfoType = new StructType('TokenInfo', [
  new FieldDefinition('token_id', '', new BytesType()),
  new FieldDefinition('nonce', '', new U64Type()),
  new FieldDefinition('amount', '', new BigUIntType())
]);

export const PaymentTokenInfoType = new StructType('PaymentTokenInfo', [
  new FieldDefinition('token_id', '', new BytesType()),
  new FieldDefinition('nonce', '', new U64Type()),
  new FieldDefinition('amount', '', new BigUIntType())
]);

export const TokenMultiplierType = new StructType('TokenMultiplierType', [
  new FieldDefinition('token', '', new BytesType()),
  new FieldDefinition('nonce', '', new U64Type()),
  new FieldDefinition('multiplier_times_10000', '', new U64Type())
]);
