import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import getAccountNfts from 'apiRequests/getAccountNfts';
import getAccountToken from 'apiRequests/getAccountToken';
import getNftsById from 'apiRequests/getNftsById';
import { ScContext } from 'components/ScProvider';
import { boosterCollection, nftsCollection, token } from 'config';
import { TokenMultiplierType } from 'pages/Dashboard/types';
import multipliers from 'multipliers.json';
import { isJsxFragment } from 'typescript';
import { AccountInfoSliceType } from '@multiversx/sdk-dapp/reduxStore/slices';
import {
  U64Value,
  BinaryCodec,
  U8Value,
  AddressValue,
  Address,
  U64Type,
  ListType,
  TupleType,
  U32Value,
  U32Type,
  ResultsParser
} from '@multiversx/sdk-core/out';
import {
  useGetNetworkConfig,
  useGetAccountInfo,
  useTrackTransactionStatus
} from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';

export interface AccountDetails {
  account?: any;
  address?: string;
  nfts?: any[];
  boosters?: any[];
  normalFarmStakedNfts?: any[];
  superFarmStakedNfts?: any[];
  stakedBoosters?: any[];
  normalFarmReward?: any;
  superFarmReward?: any;
  tokenAmmount?: string;
  normalFarmUnstakedPending?: any[];
  superFarmUnstakedPending?: any[];
  normalFarmTotalNfts?: any;
  superFarmTotalNfts?: any;
  multipliers?: any;
  refreshDetails?: (sessionId: string) => void;
  loadAllStakingAddresses?: (farmType: FarmType) => Promise<string[]>;
}

enum FarmType {
  SuperFarm = 1,
  NormalFarm
}

export const AccountDetailsContext = React.createContext<AccountDetails | null>(
  null
);

const groupBy = (xs: any, key: any) => {
  return xs.reduce((rv: any, x: any) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const AccountDetailsProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [details, setDetails] = useState<AccountDetails>({
    normalFarmReward: new U64Value(0).valueOf(),
    superFarmReward: new U64Value(0).valueOf()
  });

  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [sessionId, setSessionId] = useState<string | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { contract, abiRegistry } = React.useContext(ScContext)!;

  const onTxSuccess = () => {
    refreshDetails();
    const toastWrapper = document.getElementsByClassName(
      'transactions-toast-list_wrapper toast-messages d-flex flex-column align-items-center justify-content-sm-end'
    )[0];
    if (!toastWrapper || !toastWrapper.firstChild) return;
    const child = toastWrapper.firstChild;
    setTimeout(() => {
      toastWrapper.removeChild(child);
    }, 10000);
  };

  const txTracker = useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: onTxSuccess
  });

  const {
    network: { apiAddress }
  } = useGetNetworkConfig();
  const proxy = new ProxyNetworkProvider('https://gateway.multiversx.com');
  const { address, account } = useGetAccountInfo();
  // const { account } = useGetAccountInfo();
  // const address =
  //   'erd14edl4fvr8wc2sczrz5af6tfmfucgqzsh4u73hnxw96l07cekdg6schwtsh';
  const codec = new BinaryCodec();

  const loadAccountNfts = async () => {
    const { data, success } = await getAccountNfts({
      apiAddress: 'https://api.multiversx.com',
      address: address,
      collections: [nftsCollection]
    });

    return success ? data : [];
  };

  const loadAccountToken = async () => {
    const { data, success } = await getAccountToken({
      apiAddress: 'https://api.multiversx.com',
      address: address,
      identifier: token
    });

    return success ? data.balance : 0;
  };

  const loadAccountBoosters = async () => {
    const { data, success } = await getAccountNfts({
      apiAddress: 'https://api.multiversx.com',
      address: address,
      collections: [boosterCollection]
    });

    return success ? data : [];
  };

  const loadStakedNfts = async (farmId: FarmType) => {
    if (!farmId) return [];
    const query = contract.methods.getStorageStakedNfts([farmId, address]);

    try {
      const queryResult = await proxy.queryContract(query.check().buildQuery());
      if (!queryResult.returnData.length || queryResult.returnCode !== 'ok') {
        console.log(queryResult);
        // debugger;

        return;
      }

      const resultsParser = new ResultsParser();
      const nonces = resultsParser
        .parseQueryResponse(queryResult, query.getEndpoint())
        .firstValue?.valueOf()
        .map((v: any) => v.toNumber());

      const stakedNfts = nonces.map((nonceNumber: any) => {
        const identifier = 'MAIARPUNKS-3db7e8';
        const nonce = Number(nonceNumber).toString(16);

        return `${identifier}-${nonce.padStart(
          nonce.length + (nonce.length % 2),
          '0'
        )}`;
      });

      // debugger;

      const { data, success } = await getNftsById({
        apiAddress: 'https://api.multiversx.com',
        identifiers: stakedNfts
      });

      // debugger;

      return success ? data : [];
    } catch (e) {
      // debugger;
      console.log(e);
      return [];
    }
  };

  const loadStakedBoosters = async () => {
    const query = contract.methodsExplicit
      .getUserStakedBoosters([new AddressValue(new Address(address))])
      .buildQuery();

    const queryResult = await proxy.queryContract(query);

    if (!queryResult.returnData.length || queryResult.returnCode !== 'ok') {
      return;
    }

    const stakedNfts = queryResult.returnData.map((r: any) => {
      const decodedResult = codec.decodeNested(
        Buffer.from(r, 'base64'),
        abiRegistry.getStruct('PaymentTokenInfo')
      );
      const identifier = decodedResult[0].valueOf().token_id.toString();
      const nonce = Number(decodedResult[0].valueOf().nonce.valueOf()).toString(
        16
      );

      return `${identifier}-${Number(nonce)
        .toString(16)
        .padStart(nonce.length + (nonce.length % 2), '0')}`;
    });
    const { data, success } = await getNftsById({
      apiAddress,
      identifiers: stakedNfts
    });

    return success ? data : [];
  };

  const loadRewards = async (farmId: FarmType) => {
    if (!farmId) return new U64Value(0).valueOf();
    const query = contract.methodsExplicit
      .getRewards([new U8Value(farmId), new AddressValue(new Address(address))])
      .buildQuery();

    const queryResult = await proxy.queryContract(query);

    if (!queryResult.returnData[0] || queryResult.returnCode !== 'ok') {
      return new U64Value(0).valueOf();
    }
    const val = codec.decodeTopLevel(
      Buffer.from(queryResult.returnData[0], 'base64'),
      new U64Type()
    );

    // return val.valueOf().div(10e18);
    return val.valueOf().div(1_000_000_000_000_000_000);
  };

  const loadUnstakedPending = async (farmId: FarmType) => {
    if (!farmId) return [];
    const query = contract.methodsExplicit
      .getUserPendingUnstake([
        new U8Value(farmId),
        new AddressValue(new Address(address))
      ])
      .buildQuery();

    const queryResult = await proxy.queryContract(query);
    if (!queryResult.returnData[0] || queryResult.returnCode !== 'ok') {
      return [];
    }
    const oneDay = 86400;
    const pendingUnstake: any = {};
    queryResult.returnData.forEach((e: any) => {
      const { field0: unstakedTime, field1: nfts } = codec
        .decodeTopLevel(
          Buffer.from(e, 'base64'),
          new ListType(
            new TupleType(
              new U64Type(),
              new ListType(abiRegistry.getStruct('PaymentTokenInfo'))
            )
          )
        )
        .valueOf()[0];
      const now = Math.round(new Date().getTime() / 1000);
      nfts.forEach((nft: any) => {
        const nonce = Number(nft.valueOf().nonce.valueOf()).toString(16);
        pendingUnstake[
          `${nft.token_id}-${nonce.padStart(
            nonce.length + (nonce.length % 2),
            '0'
          )}`
        ] = Number(unstakedTime.valueOf()) + oneDay - now;
      });
    });
    const { data, success } = await getNftsById({
      apiAddress: 'https://api.multiversx.com',
      identifiers: Object.keys(pendingUnstake)
    });

    if (!success) {
      return [];
    }

    return data.map((nft: any) => {
      return { ...nft, timeToClaim: pendingUnstake[nft.identifier] };
    });
  };

  const loadTotalStakedNfts = async (farmId: FarmType) => {
    // if (!farmId) return new U32Value(0).valueOf();
    // const query = contract.methodsExplicit
    //   .getReceivedNftsTotalNumber([new U8Value(farmId)])
    //   .buildQuery();

    // const queryResult = await proxy.queryContract(query);

    // if (!queryResult.returnData[0] || queryResult.returnCode !== 'ok') {
    //   return new U32Value(0).valueOf();
    // }
    // const val = codec.decodeTopLevel(
    //   Buffer.from(queryResult.returnData[0], 'base64'),
    //   new U32Type()
    // );

    // return val.valueOf();
    return new U32Value(0).valueOf();
  };

  const loadAllStakingAddresses = async (farmType: FarmType) => {
    const query = contract.methodsExplicit
      .getAllAddresses([new U8Value(farmType)])
      .buildQuery();
    console.log('Querying info');
    const queryResult = await proxy.queryContract(query);
    if (queryResult.returnCode !== 'ok') {
      window.alert('Could not fetch staking addresses');
      return [];
    } else {
      return queryResult.returnData.map((qr: any) =>
        new Address(Buffer.from(qr, 'base64')).bech32()
      );
    }
  };

  const refreshDetails = async () => {
    if (!address) {
      setDetails({
        loadAllStakingAddresses: loadAllStakingAddresses
      });
      return;
    }
    const [
      nfts,
      boosters,
      normalFarmStakedNfts,
      superFarmStakedNfts,
      normalFarmReward,
      superFarmReward,
      normalFarmUnstakedPending,
      superFarmUnstakedPending,
      stakedBoosters,
      normalFarmTotalNfts,
      superFarmTotalNfts,
      tokenAmmount
    ] = await Promise.allSettled([
      loadAccountNfts(),
      loadAccountBoosters(),
      loadStakedNfts(FarmType.NormalFarm),
      loadStakedNfts(FarmType.SuperFarm),
      loadRewards(FarmType.NormalFarm),
      loadRewards(FarmType.SuperFarm),
      loadUnstakedPending(FarmType.NormalFarm),
      loadUnstakedPending(FarmType.SuperFarm),
      loadStakedBoosters(),
      loadTotalStakedNfts(FarmType.NormalFarm),
      loadTotalStakedNfts(FarmType.SuperFarm),
      loadAccountToken()
    ]);

    const obj: AccountDetails = {
      account,
      address,
      nfts: (nfts.status === 'fulfilled' && nfts.value) || [],
      boosters: (boosters.status === 'fulfilled' && boosters.value) || [],
      normalFarmStakedNfts:
        (normalFarmStakedNfts.status === 'fulfilled' &&
          normalFarmStakedNfts.value) ||
        [],
      superFarmStakedNfts:
        (superFarmStakedNfts.status === 'fulfilled' &&
          superFarmStakedNfts.value) ||
        [],
      normalFarmReward:
        (normalFarmReward.status === 'fulfilled' && normalFarmReward.value) ||
        new U64Value(0).valueOf(),
      superFarmReward:
        (superFarmReward.status === 'fulfilled' && superFarmReward.value) ||
        new U64Value(0).valueOf(),
      normalFarmUnstakedPending:
        (normalFarmUnstakedPending.status === 'fulfilled' &&
          normalFarmUnstakedPending.value) ||
        [],
      superFarmUnstakedPending:
        (superFarmUnstakedPending.status === 'fulfilled' &&
          superFarmUnstakedPending.value) ||
        [],
      stakedBoosters:
        (stakedBoosters.status === 'fulfilled' && stakedBoosters.value) || [],
      tokenAmmount:
        (tokenAmmount.status === 'fulfilled' && tokenAmmount.value) || 0,
      normalFarmTotalNfts:
        (normalFarmTotalNfts.status === 'fulfilled' &&
          normalFarmTotalNfts.value) ||
        new U64Value(0).valueOf(),
      superFarmTotalNfts:
        (superFarmTotalNfts.status === 'fulfilled' &&
          superFarmTotalNfts.value) ||
        new U64Value(0).valueOf(),
      multipliers,
      refreshDetails: (sid) => {
        setSessionId(sid);
      },
      loadAllStakingAddresses: loadAllStakingAddresses
    };
    setDetails({ ...details, ...obj });
    return obj;
  };

  React.useEffect(() => {
    setLoading(true);
    refreshDetails().then((e) => {
      setLoading(false);
    });
  }, [location, contract]);

  return (
    <AccountDetailsContext.Provider value={details}>
      {loading ? (
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 5000,
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '3em'
          }}
        >
          LOADING
        </div>
      ) : (
        children
      )}
    </AccountDetailsContext.Provider>
  );
};
export default AccountDetailsProvider;
