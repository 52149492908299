export const contractAddress =
  'erd1qqqqqqqqqqqqqpgqtm8dv4jqyeuvw40nygq023thg94ajgqkyl5sdacrxs';

export const dAppName = 'MaiarPunks';

export const nftsCollection = 'MAIARPUNKS-3db7e8';
export const token = 'TOLKEN-a9eb7f';
export const boosterCollection = 'MPPOTIONS-f3361b';
export const environment = 'mainnet';
export const walletConnectV2Id = 'aa9bdf013aee23e4d3be5c2b52591a0d';
