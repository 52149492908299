import axios from 'axios';
interface GetNftsByIdType {
  apiAddress: string;
  identifiers: string[];
}

export default async ({ apiAddress, identifiers }: GetNftsByIdType) => {
  const arrays = [];
  const size = 50;

  for (let i = 0; i < identifiers.length; i += size) {
    arrays.push(identifiers.slice(i, i + size));
  }

  const promises = arrays.map((id) => {
    return new Promise(async (res) => {
      try {
        const { data } = await axios.get(`${apiAddress}/nfts`, {
          params: {
            identifiers: id.join(','),
            size: 100
          }
        });

        res(data || []);
      } catch (e) {
        res([]);
      }
    });
  });

  const dataChunks = await Promise.all(promises);
  const data = Array.prototype.concat.apply([], dataChunks);
  return { data: data, success: true };
};
