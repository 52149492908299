import axios from 'axios';
interface GetAccountNftsType {
  apiAddress: string;
  address: string;
  collections: string[];
}

export default async ({
  apiAddress,
  address,
  collections
}: GetAccountNftsType) => {
  try {
    const { data } = await axios.get(`${apiAddress}/accounts/${address}/nfts`, {
      params: {
        collections: collections.join(','),
        size: 100
      }
    });

    return {
      data: data,
      success: data !== undefined
    };
  } catch (err) {
    return {
      success: false
    };
  }
};
