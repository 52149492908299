import axios from 'axios';
interface GetAccountTokenType {
  apiAddress: string;
  identifier: string;
  address: string;
}

export default async ({
  apiAddress,
  address,
  identifier
}: GetAccountTokenType) => {
  try {
    const { data } = await axios.get(
      `${apiAddress}/accounts/${address}/tokens/${identifier}`
    );

    return {
      data: data,
      success: data !== undefined
    };
  } catch (err) {
    return {
      success: false
    };
  }
};
