import React, { useState } from 'react';
import {
  Address,
  BigUIntValue,
  BytesValue,
  Field,
  List,
  ListType,
  Struct,
  U64Value
} from '@multiversx/sdk-core';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { Row, Col, Button } from 'react-bootstrap';
import { AccountDetailsContext } from 'components/AccountDetails';
import '../style.scss';
import NftCard from 'components/NftCard';
import { ScContext } from 'components/ScProvider';
import { PaymentTokenInfoType, TokenInfoType } from '../types';
import StakeNftsModal from './StakeNftsModal';

const StakedNormalFarm = () => {
  const [selectedNfts, setSelectedNfts] = useState<any[]>([]);

  const accountDetails = React.useContext(AccountDetailsContext);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { contract } = React.useContext(ScContext)!;
  const { network } = useGetNetworkConfig();

  const onSelectedNft = (nft: any, state: boolean) => {
    let newSelected = [];
    if (state) {
      newSelected = [...selectedNfts, nft];
    } else {
      newSelected = selectedNfts.filter((n) => n.identifier !== nft.identifier);
    }

    setSelectedNfts(newSelected);
  };

  const unstakeNfts = async () => {
    if (selectedNfts.length === 0) return;
    if (!accountDetails?.account) return;

    const networkAccount = await refreshAccount();
    if (!networkAccount) return;

    const baseGasLimit = 30_000_000;
    const crossShardGasLimitIncrease =
      accountDetails.account.shard !== 1 ? 20_000_000 : 0;
    const gasPerNft = 2_000_000;
    let totalGasLimit =
      baseGasLimit +
      (gasPerNft + crossShardGasLimitIncrease) * selectedNfts.length;
    if (totalGasLimit > 600_000_000) totalGasLimit = 600_000_000;

    const transaction = contract.methods
      .exitNormalFarm([
        new List(
          new ListType(PaymentTokenInfoType),
          selectedNfts.map((n) => {
            return new Struct(PaymentTokenInfoType, [
              new Field(new BytesValue(Buffer.from(n.collection)), 'token_id'),
              new Field(new U64Value(n.nonce), 'nonce'),
              new Field(new BigUIntValue(1), 'amount')
            ]);
          })
        )
      ])
      .withSender(new Address(accountDetails.address))
      .withGasLimit(totalGasLimit)
      .withChainID('1')
      .check()
      .buildTransaction();

    const { sessionId: sid, error } = await sendTransactions({
      transactions: [transaction],
      transactionsDisplayInfo: {
        processingMessage: 'Unstaking NFTs',
        errorMessage: 'An error has occured during unstaking',
        successMessage: 'Transaction successful',
        transactionDuration: 10000
      }
    });

    if (!error && sid) {
      accountDetails.refreshDetails?.(sid);
    }
  };

  const claimReward = async () => {
    if (!accountDetails?.account) return;

    const networkAccount = await refreshAccount();
    if (!networkAccount) return;
    const gasPerNft = 35_000_000;
    let gasLimit =
      250_000_000 +
      gasPerNft * (accountDetails?.normalFarmStakedNfts?.length || 0);
    if (gasLimit > 600_000_000) {
      gasLimit = 600_000_000;
    }
    const transaction = contract.methods
      .claimNormalFarmReward([])
      .withGasLimit(gasLimit)
      .withChainID('1')
      .withSender(new Address(accountDetails.address))
      .check()
      .buildTransaction();

    const { sessionId: sid, error } = await sendTransactions({
      transactions: [transaction],
      transactionsDisplayInfo: {
        processingMessage: 'Claiming normal farm rewards',
        errorMessage: 'An error has occured during claiming rewards',
        successMessage: 'Transaction successful',
        transactionDuration: 10000
      }
    });
    if (!error && sid) {
      accountDetails.refreshDetails?.(sid);
    }
  };

  const claimUnstaked = async () => {
    if (!accountDetails?.account) return;

    const networkAccount = await refreshAccount();
    if (!networkAccount) return;

    const baseGasLimit = 30_000_000;
    const crossShardGasLimitIncrease =
      accountDetails.account.shard !== 1 ? 20_000_000 : 0;
    const gasPerNft = 2_000_000;
    let totalGasLimit =
      baseGasLimit +
      (gasPerNft + crossShardGasLimitIncrease) *
        (accountDetails?.normalFarmUnstakedPending?.length || 0);
    if (totalGasLimit > 600_000_000) totalGasLimit = 600_000_000;

    const transaction = contract.methods
      .claimNormalFarmNfts([])
      .withGasLimit(totalGasLimit)
      .withChainID('1')
      .withSender(new Address(accountDetails.address))
      .check()
      .buildTransaction();

    const { sessionId: sid, error } = await sendTransactions({
      transactions: [transaction],
      transactionsDisplayInfo: {
        processingMessage: 'Unstaking NFTs',
        errorMessage: 'An error has occured during unstaking',
        successMessage: 'Transaction successful',
        transactionDuration: 10000
      }
    });
    if (!error && sid) {
      accountDetails.refreshDetails?.(sid);
    }
  };

  return (
    <div className='card-wrapper'>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h4>Normal Farm</h4>
        <div>
          <Button
            onClick={claimReward}
            variant='warning'
            className='mb-3 mr-3'
            disabled={accountDetails?.normalFarmReward?.lte(0)}
          >
            Claim {accountDetails?.normalFarmReward?.toFixed(2) || 0}
          </Button>
          <Button
            variant='warning'
            className='mb-3 mr-3'
            disabled={selectedNfts.length === 0}
            onClick={unstakeNfts}
          >
            Unstake selected
          </Button>
          <Button
            variant='warning'
            className='mb-3'
            onClick={claimUnstaked}
            disabled={
              (accountDetails?.normalFarmUnstakedPending?.length || 0) === 0
            }
          >
            Claim unstaked
          </Button>
        </div>
      </div>
      <Row>
        <Col lg={2} md={3} xs={6} className='mb-3'>
          <StakeNftsModal
            nfts={accountDetails?.nfts || []}
            stakeType='normal'
          />
        </Col>
        {accountDetails?.normalFarmStakedNfts?.map((nft) => (
          <Col lg={2} md={3} xs={6} key={nft.identifier} className='mb-3'>
            <NftCard nft={nft} onSelected={onSelectedNft} type={false} />
          </Col>
        ))}
        {accountDetails?.normalFarmUnstakedPending?.map((nft) => (
          <Col lg={2} md={3} xs={6} key={nft.identifier} className='mb-3'>
            <NftCard
              nft={nft}
              selected={false}
              time={nft.timeToClaim}
              type={false}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default StakedNormalFarm;
