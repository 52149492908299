import React, { useState } from 'react';
import { AccountDetailsContext } from 'components/AccountDetails';
import { Props } from './types';
import './style.scss';

function classNames(classes: any) {
  return Object.entries(classes)
    .map(([key, value]) => (value ? key : ''))
    .join(' ');
}

function secondsToStr(s: number) {
  function numberEnding(number: number) {
    return number > 1 ? 's' : '';
  }

  let temp = Math.floor(s);
  const years = Math.floor(temp / 31536000);
  if (years) {
    return years + ' year' + numberEnding(years);
  }
  const days = Math.floor((temp %= 31536000) / 86400);
  if (days) {
    return days + ' day' + numberEnding(days);
  }
  const hours = Math.floor((temp %= 86400) / 3600);
  if (hours) {
    return hours + ' hour' + numberEnding(hours);
  }
  const minutes = Math.floor((temp %= 3600) / 60);
  if (minutes) {
    return minutes + ' minute' + numberEnding(minutes);
  }
  const seconds = temp % 60;
  if (seconds) {
    return seconds + ' second' + numberEnding(seconds);
  }
  return 'less than a second'; //'just now' //or other string you like;
}

const NftCard = ({
  nft,
  onSelected,
  selected: selectedProp,
  type,
  time
}: Props) => {
  const [selected, setSelected] = useState(false);
  const isControlled = selectedProp !== undefined;

  const [countdown, setCountdown] = useState(time || 0);

  const select = isControlled ? selectedProp : selected;
  const accountDetails = React.useContext(AccountDetailsContext);

  React.useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }

    if (countdown === 0) {
      setCountdown(0);
    }
  }, [countdown]);

  const handleClick = () => {
    if (!isControlled) {
      setSelected(!select);
    }

    onSelected?.(nft, !select);
  };
  const nonce = Number(nft.nonce).toString(16);

  return (
    <div
      className={classNames({ 'nft-card': true, selected: select })}
      onClick={handleClick}
    >
      <img src={nft.url} />
      <div className='nft-tags'>
        <span>{nft.name}</span>
        <span style={{ color: 'rgb(0,0,255)' }}>
          {time !== undefined
            ? countdown > 0
              ? 'Claim in ' + secondsToStr(countdown)
              : 'Claim now'
            : 'Multiplier:' +
              (type
                ? '2'
                : (accountDetails?.multipliers?.[
                    nonce.padStart(nonce.length + (nonce.length % 2), '0')
                  ] || 0) /
                    10000 +
                  'x')}
        </span>
      </div>
    </div>
  );
};

export default NftCard;
