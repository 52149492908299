import axios from 'axios';
import { nftsCollection } from 'config';
interface GetAccountNftsType {
  apiAddress: string;
  address: string;
  collections: string[];
}

export default async (apiAddress: string) => {
  try {
    const { data } = await axios.get(
      `${apiAddress}/collections/${nftsCollection}/accounts?size=10000`
    );

    return {
      data: data,
      success: data !== undefined
    };
  } catch (err) {
    return {
      success: false
    };
  }
};
