import React, { useState } from 'react';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import {
  Address,
  BigUIntValue,
  BytesValue,
  ContractFunction,
  Field,
  List,
  ListType,
  Struct,
  TransactionPayload,
  U64Value
} from '@multiversx/sdk-core';
import { Row, Col, Button } from 'react-bootstrap';
import { AccountDetailsContext } from 'components/AccountDetails';
import '../style.scss';
import NftCard from 'components/NftCard';
import { ScContext } from 'components/ScProvider';
import { contractAddress } from 'config';
import { PaymentTokenInfoType, TokenInfoType } from '../types';
import StakeNftsModal from './StakeNftsModal';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';

const StakedSuperFarm = () => {
  const [selectedNfts, setSelectedNfts] = useState<any[]>([]);
  const [selectedBoosters, setSelectedBoosters] = useState<any[]>([]);

  const accountDetails = React.useContext(AccountDetailsContext);

  const { contract } = React.useContext(ScContext) || {}; // Add null check

  const { network } = useGetNetworkConfig();

  const onSelectedNft = (nft: any, state: boolean) => {
    let newSelected = [];
    if (state) {
      newSelected = [...selectedNfts, nft];
    } else {
      newSelected = selectedNfts.filter((n) => n.identifier !== nft.identifier);
    }

    setSelectedNfts(newSelected);
  };

  const onSelectedBooster = (booster: any, state: boolean) => {
    let newSelected = [];
    if (state) {
      newSelected = [...selectedBoosters, booster];
    } else {
      newSelected = selectedBoosters.filter(
        (n) => n.identifier !== booster.identifier
      );
    }

    setSelectedBoosters(newSelected);
  };

  const unstakeNfts = async () => {
    if (contract === null || contract === undefined) return;
    if (selectedNfts.length === 0 && selectedBoosters.length === 0) return;
    if (!accountDetails?.account) return;

    const networkAccount = await refreshAccount();
    if (!networkAccount) return;

    const baseGasLimit = 30_000_000;
    const crossShardGasLimitIncrease =
      accountDetails.account.shard !== 1 ? 20_000_000 : 0;
    const gasPerNft = 2_000_000;
    let totalGasLimit =
      baseGasLimit +
      (gasPerNft + crossShardGasLimitIncrease) *
        (selectedNfts.length + selectedBoosters.length);
    if (totalGasLimit > 600_000_000) totalGasLimit = 600_000_000;

    const transaction = contract.methods
      .exitSuperFarm([
        new List(new ListType(PaymentTokenInfoType), [
          ...selectedNfts.map((n) => {
            return new Struct(PaymentTokenInfoType, [
              new Field(new BytesValue(Buffer.from(n.collection)), 'token_id'),
              new Field(new U64Value(n.nonce), 'nonce'),
              new Field(new BigUIntValue(1), 'amount')
            ]);
          }),
          ...selectedBoosters.map((n) => {
            return new Struct(PaymentTokenInfoType, [
              new Field(new BytesValue(Buffer.from(n.collection)), 'token_id'),
              new Field(new U64Value(n.nonce), 'nonce'),
              new Field(new BigUIntValue(1), 'amount')
            ]);
          })
        ])
      ])
      .withSender(new Address(accountDetails.address))
      .withGasLimit(totalGasLimit)
      .withChainID('1')
      .check()
      .buildTransaction();

    const { sessionId: sid, error } = await sendTransactions({
      transactions: [transaction],
      transactionsDisplayInfo: {
        processingMessage: 'Staking NFTs',
        errorMessage: 'An error has occured during staking',
        successMessage: 'Transaction successful',
        transactionDuration: 10000
      }
    });
    if (!error && sid) {
      accountDetails.refreshDetails?.(sid);
    }
  };

  const claimReward = async () => {
    if (contract === null || contract === undefined) return;
    if (!accountDetails?.account) return;

    const networkAccount = await refreshAccount();
    if (!networkAccount) return;
    const gasPerNft = 55_000_000;
    let gasLimit =
      190_000_000 +
      gasPerNft * (accountDetails?.superFarmStakedNfts?.length || 0);
    if (gasLimit > 600_000_000) {
      gasLimit = 600_000_000;
    }
    const transaction = contract.methods
      .claimSuperFarmReward([])
      .withGasLimit(gasLimit)
      .withChainID('1')
      .withSender(new Address(accountDetails.address))
      .check()
      .buildTransaction();

    const { sessionId: sid, error } = await sendTransactions({
      transactions: [transaction],
      transactionsDisplayInfo: {
        processingMessage: 'Claiming super farm rewards',
        errorMessage: 'An error has occured during claiming rewards',
        successMessage: 'Transaction successful',
        transactionDuration: 10000
      }
    });
    if (!error && sid) {
      accountDetails.refreshDetails?.(sid);
    }
  };

  const claimUnstaked = async () => {
    if (contract === null || contract === undefined) return;
    if (!accountDetails?.account) return;

    const networkAccount = await refreshAccount();
    if (!networkAccount) return;

    const baseGasLimit = 30_000_000;
    const crossShardGasLimitIncrease =
      accountDetails.account.shard !== 1 ? 20_000_000 : 0;
    const gasPerNft = 2_000_000;
    let totalGasLimit =
      baseGasLimit +
      (gasPerNft + crossShardGasLimitIncrease) *
        (accountDetails?.superFarmUnstakedPending?.length || 0);
    if (totalGasLimit > 600_000_000) totalGasLimit = 600_000_000;

    const transaction = contract.methods
      .claimSuperFarmNfts([])
      .withGasLimit(totalGasLimit)
      .withChainID('1')
      .withSender(new Address(accountDetails.address))
      .check()
      .buildTransaction();

    const { sessionId: sid, error } = await sendTransactions({
      transactions: [transaction],
      transactionsDisplayInfo: {
        processingMessage: 'Claiming unstaked NFTs',
        errorMessage: 'An error has occured during claiming NFTs',
        successMessage: 'Transaction successful',
        transactionDuration: 10000
      }
    });
    if (!error && sid) {
      accountDetails.refreshDetails?.(sid);
    }
  };

  return (
    <div className='card-wrapper'>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h4>Super Farm</h4>
        <div>
          <Button
            onClick={claimReward}
            variant='warning'
            className='mb-3 mr-3'
            disabled={accountDetails?.superFarmReward?.lte(0)}
          >
            Claim {accountDetails?.superFarmReward?.toFixed(2) || 0}
          </Button>
          <Button
            variant='warning'
            className='mb-3 mr-3'
            disabled={
              selectedNfts.length === 0 && selectedBoosters.length === 0
            }
            onClick={unstakeNfts}
          >
            Unstake selected
          </Button>
          <Button
            variant='warning'
            className='mb-3'
            onClick={claimUnstaked}
            disabled={
              (accountDetails?.superFarmUnstakedPending?.length || 0) === 0
            }
          >
            Claim unstaked
          </Button>
        </div>
      </div>
      <Row>
        {(accountDetails?.superFarmStakedNfts?.length || 0) < 3 && (
          <Col lg={2} md={3} xs={6} className='mb-3'>
            <StakeNftsModal
              nfts={accountDetails?.nfts || []}
              stakeType='super'
            />
          </Col>
        )}
        {accountDetails?.superFarmStakedNfts?.map((nft) => (
          <Col lg={2} md={3} xs={6} key={nft.identifier} className='mb-3'>
            <NftCard nft={nft} onSelected={onSelectedNft} type={false} />
          </Col>
        ))}
        {accountDetails?.superFarmUnstakedPending?.map((nft) => (
          <Col lg={2} md={3} xs={6} key={nft.identifier} className='mb-3'>
            <NftCard
              nft={nft}
              selected={false}
              time={nft.timeToClaim}
              type={false}
            />
          </Col>
        ))}

        <Col lg={2} md={3} xs={12} className='mb-3 ml-auto'>
          {accountDetails?.stakedBoosters?.[0] && (
            <NftCard
              nft={accountDetails?.stakedBoosters?.[0]}
              onSelected={onSelectedBooster}
              type={true}
            />
          )}
          {!accountDetails?.stakedBoosters?.[0] && (
            <StakeNftsModal
              nfts={accountDetails?.boosters || []}
              stakeType='booster'
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default StakedSuperFarm;
