import React from 'react';

const Footer = () => {
  return (
    <footer className='text-center mt-2 mb-3'>
      <div></div>
    </footer>
  );
};

export default Footer;
