import React, { useState } from 'react';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import {
  Interaction,
  SmartContract,
  Address,
  ContractFunction,
  TokenPayment,
  TokenTransfer
} from '@multiversx/sdk-core';
import {
  Button,
  Col,
  Modal,
  Row,
  Toast,
  ToastContainer
} from 'react-bootstrap';
import { AccountDetailsContext } from 'components/AccountDetails';
import NftCard from 'components/NftCard';
import { contractAddress } from 'config';
import { ScContext } from 'components/ScProvider';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';

const StakeNftsModal = ({
  stakeType,
  nfts
}: {
  stakeType: 'normal' | 'super' | 'booster';
  nfts: any[];
}) => {
  const [selectedNfts, setSelectedNfts] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const { contract } = React.useContext(ScContext)!;
  const accountDetails = React.useContext(AccountDetailsContext);

  const { network } = useGetNetworkConfig();

  const onSelectedNft = (nft: any, state: boolean) => {
    let newSelected = [];
    if (state) {
      newSelected = [...selectedNfts, nft];
    } else {
      newSelected = selectedNfts.filter((n) => n.identifier !== nft.identifier);
    }

    setSelectedNfts(newSelected);
  };

  const buildBalance = () => {
    return selectedNfts.map((nft) => {
      // return new TokenPayment(nft.collection, nft.nonce, 1, 0);
      return TokenTransfer.nonFungible(nft.collection, nft.nonce);
    });
  };

  const stakeNfts = async () => {
    if (selectedNfts.length === 0) return;
    if (!accountDetails?.account) return;
    const networkAccount = await refreshAccount();
    if (!networkAccount) return;
    if (!contract) return;

    const address = accountDetails.address;
    const baseGasLimit = 30_000_000;
    const crossShardGasLimitIncrease =
      accountDetails.account.shard !== 1 ? 10_000_000 : 0;
    const gasPerNft = 2_000_000;

    const balance = buildBalance();

    let gasLimit =
      baseGasLimit + balance.length * (gasPerNft + crossShardGasLimitIncrease);
    if (gasLimit > 600_000_000) {
      gasLimit = 600_000_000;
    }

    const interaction =
      stakeType === 'super' || stakeType === 'booster'
        ? contract.methods.enterSuperFarm([])
        : contract.methods.enterNormalFarm([]);

    const transactionBuild = interaction
      .withGasLimit(gasLimit)
      .withMultiESDTNFTTransfer(balance)
      .withSender(new Address(address))
      .withChainID('1')
      .check()
      .buildTransaction();

    try {
      const { sessionId: sid, error } = await sendTransactions({
        transactions: [transactionBuild],
        transactionsDisplayInfo: {
          processingMessage: 'Staking NFTs',
          errorMessage: 'An error has occured during staking',
          successMessage: 'Transaction successful',
          transactionDuration: 10000
        }
      });
      setShow(false);
      if (!error && sid) {
        accountDetails.refreshDetails?.(sid);
      }
    } catch (err) {
      console.log('Error sending tx', err, transactionBuild, network.chainID);
    }
  };

  return (
    <>
      <div className='add-nft-button' onClick={() => setShow(true)}>
        <i className='icon-plus-circled' style={{ fontSize: '5rem' }} />
        <span>{stakeType === 'booster' ? 'Stake Booster' : 'Stake NFT'}</span>
      </div>

      <Modal
        dialogClassName='modal-lg stakeModal modal-dialog-scrollable'
        show={show}
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>Stake</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {(nfts?.length || 0) > 0 ? (
            <Row>
              {nfts.map((nft) => (
                <Col lg={3} md={3} xs={6} key={nft.identifier} className='mb-3'>
                  <NftCard
                    nft={nft}
                    selected={
                      !!selectedNfts.find(
                        (selected) => nft.identifier === selected.identifier
                      )
                    }
                    onSelected={onSelectedNft}
                    type={stakeType === 'booster'}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span>
                {stakeType === 'booster'
                  ? 'No Boosters to stake'
                  : 'No Maiar Punks to stake'}
              </span>

              <h1></h1>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShow(false)}>
            Close
          </Button>
          <Button
            variant='warning'
            onClick={stakeNfts}
            disabled={selectedNfts.length === 0}
          >
            Stake
          </Button>
        </Modal.Footer>
      </Modal>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100vh',
          width: '100vw',
          pointerEvents: 'none',
          zIndex: '5000'
        }}
      >
        <ToastContainer className='p-3' position={'top-center'}>
          <Toast bg='dark' show={showToast}>
            <Toast.Body>
              Too many NFTs selected, remove some and try again.
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
    </>
  );
};

export default StakeNftsModal;
