import React from 'react';
import './style.scss';
import Details from './Components/Details';
import StakedNfts from './Components/StakedNfts';

const Dashboard = () => {
  return (
    <div className='container py-4 dashboard'>
      <Details />
      <StakedNfts />
    </div>
  );
};

export default Dashboard;
